import { graphql, useStaticQuery } from 'gatsby'

const useModuleSettings = () => {

	const moduleSettings = useStaticQuery(graphql`
			query {
				sanityModuleSettings {
					helpLine {
						columns {
							text: _rawText(resolveReferences: {maxDepth: 5})
							_type
							heading
							_key
						}
						eyebrow
						headline
						phoneNumber
						text
					},
					smartSearch {
						eyebrow
						text: _rawText(resolveReferences: {maxDepth: 5})
						headline
					}
					checklist {
						eyebrow
						headline
						text: _rawText(resolveReferences: {maxDepth: 5})
						checklists {
							_type
							title
							slug {
								current
							}
						}
					}
					chatbot{
						...textImage
					}
					checklistGlobalModule{
						...centeredText
					}
				}
			}
    `)

	return moduleSettings?.sanityModuleSettings
}

export default useModuleSettings
