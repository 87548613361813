import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import useModuleSettings from '~utils/useModuleSettings'
import RichText from '~components/RichText'
import { Eyebrow } from '~styles/global'
import SelectDropDown from '~components/SelectDropDown'
import audienceOptions from '../../studio/utils/audienceOptions'
import resolveLink from '~utils/resolveLink'
import Button from '~components/Button'
import SelectBox from '~components/SelectBox'

const SmartSearch = ({ className }) => {

	const { smartSearch } = useModuleSettings()
	const [currentAudience, setCurrentAudience] = useState()
	const [matchedLink, setMatchedLink] = useState()

	let pages = useStaticQuery(graphql`
		query {
			allSanityPage(
			filter: {section: {eq: "smart-search"}}
			sort: {fields: title, order: ASC}
		) {
			nodes {
				_type
				title
				slug {
					current
				}
				audience
				section
			}
		}
		}
	`)

	pages = pages.allSanityPage.nodes

	let organisedPages = {}

	audienceOptions.forEach(option => {
		organisedPages = {
			...organisedPages,
			[option.value]: pages?.filter(page => page.audience === option.value)
		}
	})

	return (
		<Wrap className={className}>
			<Col>
				<Eyebrow>{smartSearch.eyebrow}</Eyebrow>
				<Headline>{smartSearch.headline}</Headline>
				<Text>
					<RichText content={smartSearch.text}/>
				</Text>
			</Col>
			<Form>
				<FormOptions>
					<FormOption>
						<h4>I am</h4>
						<StyledSelectBox
							options={audienceOptions.map(option => ({...option, label: option.title}))}
							onChange={val => {setCurrentAudience(val), setMatchedLink(null)}}
							selected={currentAudience}
						/>
					</FormOption>
					<FormOption>
						<InfoSection active={currentAudience}>
							<h4>I’d like info about</h4>
							<StyledSelectBox
								options={currentAudience ? organisedPages[currentAudience]?.map(p => ({value: resolveLink(p), label: p.title})) : organisedPages[audienceOptions[0].value]?.map(p => ({value: resolveLink(p), label: p.title}))}
								onChange={val => setMatchedLink(val)}
								selected={matchedLink}
							/>
						</InfoSection>
					</FormOption>
				</FormOptions>
				<SubmitWrap active={matchedLink}>
					<SubmitLink to={matchedLink}>
						<Submit bgColor={matchedLink ? 'bteal' : 'bteal40'}>
						Find Resources
						</Submit>
					</SubmitLink>
				</SubmitWrap>
			</Form>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: var(--beige);
	padding: var(--xxl) 0 var(--xxxxl);
	${mobile}{
		padding: 66px 0px;
	}
`
const Col = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
	}
`

const InfoSection = styled.div`
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => props.active ? 'all' : 'none'};
`

const Form = styled(Col)`
	grid-column: span 6;
	${mobile}{
		margin-top: var(--l);
	}
	h4{
		text-align: center;
		margin-bottom: var(--l);
	}
	.select-wrap{
		display: block;
		margin: 0 auto var(--xl) auto;
		max-width: 400px;
		${mobile}{
			max-width: none;
		}
	}
`
const FormOptions = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	${mobile}{
		grid-template-columns: 1fr;
		grid-column-gap: 14px;
	}
`
const FormOption = styled.div`
	display: flex;
  flex-direction: column;
`
const StyledSelectBox = styled(SelectBox)`
	margin-top: auto;
`
const SubmitWrap = styled.div`
	text-align: center;
	opacity: ${props => props.active ? 1 : 0.5};
	pointer-events: ${props => props.active ? 'all' : 'none'};
`
const SubmitLink = styled(Link)`
	display: block;
	max-width: 400px;
	margin: 0 auto;
`
const Submit = styled(Button)`
	margin: 0 auto;
	width: 100%;
	max-width: 400px;
	color: var(--dteal);
	:hover{
		color: var(--dteal);
	}
`

const Headline = styled.h2`
	margin-bottom: var(--m);
`
const Text = styled.div`
	margin-bottom: var(--s);
	max-width: 488px;
`
SmartSearch.propTypes = {
	className: PropTypes.string
}

export default SmartSearch