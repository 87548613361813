import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useModuleSettings from '~utils/useModuleSettings'
import { Eyebrow } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'

const HelpLine = ({ className}) => {

	const { helpLine } = useModuleSettings()

	return (
		<Wrap className={className}>
			<Container>
				<Eyebrow>{helpLine.eyebrow}</Eyebrow>
				<LargeText>{helpLine.headline}</LargeText>
				<LargeText>{helpLine.phoneNumber}</LargeText>
				<Sub>{helpLine.text}</Sub>
			</Container>
			{helpLine.columns.map(column => (
				<Column key={column._key}>
					<CHeading className='para'>{column.heading}</CHeading>
					<CText>
						<RichText content={column.text}/>
					</CText>
				</Column>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxxl) 0;
	background-color: var(--orange60);
	${mobile}{
		padding: 64px 0;
	}
`
const LargeText = styled.h2`
	margin-bottom: var(--l);
`
const Sub = styled.h4`
	margin-bottom: var(--l);
`
const Container = styled.div`
	grid-column: span 12;
`
const Column = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--l);
		&:last-of-type {
			margin-bottom: 0px;	
		}
	}
`
const CHeading = styled.h6`
	margin-bottom: var(--s);
`
const CText = styled.div`
	
`
HelpLine.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
}

export default HelpLine