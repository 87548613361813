import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'

const TextColumns = ({ className, content }) => {

	return (
		<Wrap className={className}>
			<Heading>
				{content.title}
			</Heading>
			{content?.columns.map((column, i) => (
				<Column key={`column${i}`}>
					<RichText content={column.text}/>
				</Column>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: 100px 0;
	${mobile}{
		margin: 64px 0;
	}
`
const Heading = styled.h3`
	grid-column: span 12;
	margin-bottom: var(--xl);
`
const Column = styled.div`
	border-top: 3px solid var(--bteal);
	padding-top: var(--s);
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--l);
		:last-of-type{
			margin-bottom: 0px;
		}
	}
`
TextColumns.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextColumns