import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, ULI, BulletPoint } from '~styles/global'
import Section from '~components/Section'
import { Eyebrow } from '~styles/global'
import RichText from '~components/RichText'
import Image from '~components/Image'
import { paramCase } from 'change-case'
import Quote from '~components/Quote'

const SignsSymptoms = ({ className, content, id }) => {
	return (
		<Wrap className={className} id={id}>
			<LeftCol>
				<ImageMobile image={content.image}/>
				<Eyebrow>{content.eyebrow}</Eyebrow>
				<Intro className='para'>
					<RichText content={content.introduction}/>
				</Intro>
				{content.quoteText?.length > 0 && 
					<QuoteMobile quote={content.quoteText} attribution={content.quoteAttribution}/>
				}
				<Symptoms>
					{content.symptoms.map((symptom, i) => (
						<ULI key={`symptom${i}`}>
							<BulletPoint />
							<span>{symptom}</span>
						</ULI>
					))}
				</Symptoms>
			</LeftCol>
			<RightCol>
				<ImageDesktop image={content.image}/>
				{content.quoteText?.length > 0 && 
					<QuoteDesktop quote={content.quoteText} attribution={content.quoteAttribution}/>
				}
			</RightCol>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: var(--peach40);
	padding: var(--xxxxl) 0;
	${mobile}{
		padding: 64px 0;
	}
`
const LeftCol = styled.div`
	grid-column: 1/6;
	${mobile}{
		grid-column: span 12;
	}
`
const RightCol = styled.div`
	grid-column: 7/13;
	${mobile}{
		grid-column: span 12;
	}
`
const ImageDesktop = styled(Image)`
	display: block;
	${mobile}{
		display: none;
	}
`
const ImageMobile = styled(Image)`
	display: none;
	${mobile}{
		display: block;
		margin-bottom: 46px;
	}
`
const Intro = styled.div`
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: 46px;
	}
`
const Symptoms = styled.ul`
	list-style: none;
`
const QuoteDesktop = styled(Quote)`
	margin-top: 0px;
	${mobile}{
		display: none;
	}
`
const QuoteMobile = styled(Quote)`
	display: none;
	${mobile}{
		display: flex;
		margin-bottom: 136px;
	}
`

SignsSymptoms.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	id: PropTypes.string,
}

export default SignsSymptoms