import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import Section from '~components/Section'
import { Eyebrow } from '~styles/global'
import RichText from '~components/RichText'
import Image from '~components/Image'

const CenteredText = ({ className, content, largeHeading }) => {
	return (
		<Wrap className={className} bgColor={content?.background?.value}>
			<SideImage image={content.leftImage}/>
			<Container>
				<Eyebrow>{content.eyebrow}</Eyebrow>
				<Heading className={largeHeading ? 'h2' : ''}>{content.title}</Heading>
				<div className="para">
					<RichText moduleStyles content={content.mediumText}/>
				</div>
				<RichText moduleStyles content={content.text}/>
			</Container>
			<SideImage image={content.rightImage}/>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: ${props => props.bgColor};
	padding: 100px 0;
	> div {
		align-items: center;
	}
	${mobile}{
		padding: 64px 0;
	}
`
const Container = styled.div`
	grid-column: 4/10;
	text-align: center;
	${tablet}{
		grid-column: 3/11;
	}
	${mobile}{
		grid-column: span 12;
	}

`
const Heading = styled.h3`
	margin-bottom: var(--m);
`

const SideImage = styled(Image)`
	grid-column: span 3;
	${tablet}{
		display: none;
	}
`

CenteredText.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string,
}

export default CenteredText