import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SignsSymptoms from '~components/SignsSymptoms'
import Collection from '~components/Collection'
import HelpLine from '~components/HelpLine'
import QuickLinks from '~components/QuickLinks'
import SmartSearch from '~components/SmartSearch'
import Checklist from '~components/Checklist'
import TextImage from '~components/TextImage'
import FaqBlock from '~components/FaqBlock'
import LogoGrid from '~components/LogoGrid'
import CenteredText from '~components/CenteredText'
import FileCollection from '~components/FileCollection'
import Team from '~components/Team'
import FreeText from '~components/FreeText'
import VideoModule from '~components/VideoModule'
import ContactForm from '~components/ContactForm'
import FormAssemblyEmbed from '~components/FormAssemblyEmbed'
import TextColumns from '~components/TextColumns'
import QuoteModule from '~components/QuoteModule'
import EmbedModule from '~components/EmbedModule'
import useGenerateJumpLinks from '~utils/useGenerateJumpLinks'
import ChatBotModule from '~components/ChatBotModule'
import useModuleSettings from '../utils/useModuleSettings'

const RenderModules = ({ className, modules }) => {
	const jumpLinks = useGenerateJumpLinks(modules)
	if(!modules) return null
	return (
		<div className={className}>
			{modules.map(module => (
				<div key={module._key} id={jumpLinks.find(link => link.key === module._key)?.hash}>
					<RenderModule module={module} />
				</div>
			))}
		</div>
	)
}

const RenderModule = ({ module }) => {
	const { checklistGlobalModule } = useModuleSettings()

	switch (module._type) {
	case 'signsSymptoms':
		return <SignsSymptoms content={module} />
	case 'articleCollection':
		return <Collection content={module} />
	case 'storyCollection':
		return <Collection content={module} />
	case 'helpLine':
		return <HelpLine content={module} />
	case 'smartSearch':
		return <SmartSearch content={module} />
	case 'checklist':
		return <Checklist />
	case 'checklistModule':
		return <CenteredText content={checklistGlobalModule} />
	case 'quickLinks':
		return <QuickLinks content={module} />
	case 'textImage':
		return <TextImage content={module} />
	case 'faqBlock':
		return <FaqBlock content={module} />
	case 'logoGrid':
		return <LogoGrid content={module} />
	case 'centeredText':
		return <CenteredText content={module}/>
	case 'campaign':
		return <CenteredText content={module} largeHeading/>
	case 'fileCollection':
		return <FileCollection content={module}/>
	case 'team':
		return <Team content={module}/>
	case 'freeText':
		return <FreeText content={module}/>
	case 'video':
		return <VideoModule content={module}/>
	case 'podcast':
		return <VideoModule content={module}/>
	case 'contactForm':
		return <ContactForm content={module}/>
	case 'formAssemblyEmbed':
		return <FormAssemblyEmbed content={module}/>
	case 'textColumns':
		return <TextColumns content={module}/>
	case 'quote':
		return <QuoteModule content={module}/>
	case 'productCollection':
		return <Collection content={module}/>
	case 'embed':
		return <EmbedModule content={module}/>
	case 'chatBotModule':
		return <ChatBotModule content={module} />
	default:
		return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.object,
}

RenderModule.propTypes = {
	module: PropTypes.object,
}

export default RenderModules