import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import * as Flickity from 'react-flickity-component'
import '~styles/flickity.css'
import Button from '~components/Button'
import File from '~components/File'
import useBreakpoint from '~utils/useBreakpoint'
import Section from '~components/Section'

const FileCollection = ({ className, content }) => {

	const { isDesktop, isMobile } = useBreakpoint()

	const flickityOptions = {
		prevNextButtons: true,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: true,
		arrowShape: 'M15.7192 50L56.25 91.879L48.3904 100L0 50L48.3904 0L56.25 8.12103L15.7192 50Z'
	}

	const [dragging, setDragging] = useState()

	let flkty

	useEffect(() => {
		if(flkty){
			flkty.on('dragStart', function(){
				setDragging(true)
			})
				
			flkty.on('dragEnd', function(){
				setDragging(false)
			})
		}
	}, [flkty])

	return (
		<Wrap 
			className={className}
		>
			<Container>
				<Head>
					<HeadLeft>
						<Heading 
							raised={content.files?.length > 4 || (isDesktop && content.files?.length > 1)}
						>
							{content?.title}
						</Heading>
					</HeadLeft>
				</Head>
				<Flickity
					flickityRef={c => flkty = c}
					className={'carousel'} // default ''
					elementType={'div'} // default 'div'
					options={flickityOptions} // takes flickity options {}
					disableImagesLoaded={false} // default false
					// static // default false
					css={
						content.files?.length > 4 || (isDesktop && content.files?.length > 3 || isMobile && content.files?.length > 1) ? 
							css`${flickityStyles}`
							: 
							css`display: none;`
					}
				>
					{content.files?.map((file, i) => (
						<Slide 
							index={i}
							key={file.title} 
							content={file}
							css={css`pointer-events: ${dragging ? 'none' : 'all'};`}
							bgColor={'var(--beige20)'}
						/>
					))}
				</Flickity>
				<Grid 
					css={css`
						display: ${content.files?.length > 4 ? 'none' : 'grid'};
						${desktop}{
							display: ${content.files?.length > 3 ? 'none' : 'grid'};
						}
						${mobile}{
							display: ${content.files?.length > 1 ? 'none' : 'grid'};
						}
					`}
				>
					{content.files?.map((file, i) => (
						<GridTile 
							index={i}
							key={file.title} 
							content={file}
							bgColor={'var(--beige20)'}
						/>
					))}
				</Grid>
			</Container>
		</Wrap>
	)
}


const Wrap = styled(Section)`
	padding: var(--xxxxl) 0;
	background-color: var(--beige20);
	${mobile}{
		padding: 64px 0;
		overflow: hidden;
	}
`
const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column: span 12;
	position: relative;
`
const Head = styled.div`
	grid-column: span 9;
	display: grid;
	grid-template-columns: 1fr max-content;
	margin-bottom: var(--l);
	${mobile}{
		grid-template-columns: 1fr;
		grid-column: span 12;
		margin-bottom: var(--s);
	}
`
const HeadLeft = styled.div`
`

const Heading = styled.h3`
	margin-bottom: var(--m);
	position: relative;
  top: ${props => props.raised ? '-10px' : '0'};
	${mobile}{
		top: 0;
	}
`
const Grid = styled.div`
	grid-column: span 12;
	margin-bottom: var(--l);
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 30px;
	${mobile}{
		grid-column-gap: 14px;
	}
`
const Slide = styled(File)`
	width: calc(25% - 22.5px);
	margin-right: 30px;
	box-sizing: border-box;
	${desktop}{
		width: calc(33.333% - 20.5px);
	}
	/* ${tablet}{
		width: calc(50% - 24.5px);
	} */
	${mobile}{
		width: 82.6%;
		max-width: 310px;
	}
`
const GridTile = styled(File)`
	grid-column: span 3;
	${desktop}{
		grid-column: span 4;
	}
	${mobile}{
		grid-column: span 10;
	}
`
const flickityStyles = `
	grid-column: span 12;
	${mobile}{
		position: relative;
		margin-bottom: 124px;
		.flickity-viewport {
			overflow: visible;
		}
		.flickity-prev-next-button {
			top: auto;
			bottom: -112px;
		}
	}
`

FileCollection.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default FileCollection