import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'

const EmbedModule = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{content?.embedCode &&
				<Embed>
					<EmbedInner dangerouslySetInnerHTML={{__html: content?.embedCode}} />
				</Embed>
			}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 100px 0;
	${mobile} {
		padding: 64px 0;
	}
`

const Embed = styled.div`
	grid-column: span 12;
	/* display: flex;
	justify-content: center; */
`

const EmbedInner = styled.div`
	max-width: 100%;
	display: flex;
	justify-content: center;
`

EmbedModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default EmbedModule