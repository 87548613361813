import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'

const FormAssemblyEmbed = ({ className, content }) => {
	
	const formRef = useRef()
	const [formHeight, setFormHeight] = useState(undefined)

	useEffect(() => {
		if(formRef.current){
			// setFormHeight(undefined)
		}
	}, [formRef.current])

	useEffect(() => {
		const script = document.createElement('script')
	
		script.src = 'https://tfaforms.com/js/iframe_resize_helper.js'
		script.async = true
	
		document.body.appendChild(script)
	
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<Wrap className={className}>
			{content.title && <Title>{content.title}</Title>}
			<Container>
				<Iframe 
					ref={formRef}
					src={content.formLink} 
					height='1000'
					width="100%"
					frameBorder="0" >
				</Iframe>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 100px 0;
	${mobile}{
		padding: 64px 0;
	}
`
const Title = styled.h3`
	grid-column: span 12;
	margin-bottom: var(--l);
`
const Container = styled.div`
	grid-column: span 12;
`
const Iframe = styled.iframe`
	color: red;
		.wFormContainer {
		height: auto;
	}
`
FormAssemblyEmbed.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default FormAssemblyEmbed