import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { paramCase, sentenceCase } from 'change-case'
import Input from '~components/Input'
import SelectDropDown from '~components/SelectDropDown'
import Section from '~components/Section'
import Button from '~components/Button'
import RichText from '~components/RichText'
import { mobile, tablet } from '~styles/global'

const ContactForm = ({ className, content }) => {
  
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		const msg = {
			to: content.sendTo,
			// to: 'jack@formwork.build',
			from: {
				email: 'noreply@panda.org.au',
				name: 'PANDA Website'
			},
			subject: 'New Message',
			template_id: 'd-57e1c3df199b4426956fde8e45d8fe66',
			dynamic_template_data: {
				subject: 'New Message',
				headline: 'New message form the PANDA contact form',
				fields: Object.keys(values).map(key =>({label: sentenceCase(key), value: values[key]}))
			},
		}
 
		axios.post('/api/trigger-sendgrid', msg)
			.then(res => {
				// console.log(res)
				setSuccess(true)
			})
			.catch(err =>
				console.log(err)
			)
	}

	const formName = paramCase(content?.title)

	// const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Text>
				<h3>{content?.title}</h3>
				<RichText content={content?.text}/>
			</Text>
			<Form method="POST" data-netlify="true" name={formName} noValidate onSubmit={e => validate(e)}>
				<Success css={css`
          opacity: ${success ? '1' : '0'};
          pointer-events: ${success ? 'all' : 'none'};
        `}>
					{content.successMessage}
				</Success>
				<div css={css`
            opacity: ${success ? '0' : '1'};
            pointer-events: ${success ? 'none' : 'all'};
          `}>
					{content.fields?.map(field => (
						['emailField', 'textField'].includes(field._type) ? 
							<Input 
								type={field._type === 'textField' ? 'text' : 'email'}
								onChange={e => setValues({...values, [paramCase(field.title)]: e.target.value})}
								placeholder={field.placeholder}
								id={paramCase(field.title)}
							/>
							: field._type === 'selectField' ? 
								<SelectDropDown 
									placeholder={field?.placeholder}
									onChange={e => setValues({...values, [paramCase(field.title)]: e.label})}
							
									options={field?.options?.map(option => ({value: paramCase(option), label: option}))}
								/>
								: <textarea name="message" id="message"
									onChange={e => setValues({...values, [paramCase(field.title)]: e.target.value})}
									placeholder={field?.placeholder}
									css={css`
								 grid-column: span 2;
								 height: 200px;
								 `
									} />
					))}
					{errors?.length > 0 &&
            <Errors>
            	{errors.map(error => <p className="h5">{error.message}</p>)}
            </Errors>
					}
					<Submit onClick={() => null} type="submit" bgColor="dteal">
            Submit
					</Submit>
				</div>
			</Form>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxl) 0;
	background: var(--beige);
`

const Text = styled.div`
	grid-column: span 6;
	h3{
		margin-bottom: var(--m);
	}
	${tablet}{
		grid-column: span 12;
	}
`

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
`

const Form = styled.form`
  transition: opacity 0.3s;
	position: relative;
	grid-column: span 5;
	${tablet}{
		grid-column: span 12;
		max-width: 800px;
	}
  input, textarea{
    display: block;
  }
	> div > div {
		display: block;
		width: 100%;
		margin-bottom: var(--m);
	}
`

const Errors = styled.div`
  grid-column: span 2;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--orange);
`

const Submit = styled(Button)`
	margin-top: var(--m);
	margin-left: auto;
	display: flex;
`

ContactForm.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ContactForm
