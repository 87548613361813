import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Eyebrow } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'
import RichText from '~components/RichText'

const TextImage = ({ className, content }) => {
	return (
		<Wrap 
			className={className}
			css={css`
				background-color: ${content.background ? content.background.value : 'transparent'};
			`}
		>
			<MainImage 
				aspectRatio={1}
				image={content.image}
				css={css`
					grid-column: ${content.contentOrder === 'imageFirst' ? '1/7' : '7/13'};
				`}
			/>
			<Content
				css={css`
					grid-column: ${content.contentOrder === 'imageFirst' ? '8/13' : '1/6'};
				`}
			>
				<Inner>
					<Eyebrow>
						{content.eyebrow}
					</Eyebrow>
					<Headline>
						{content.headline}
					</Headline>
					<Text>
						<RichText moduleStyles content={content.text}/>
					</Text>
				</Inner>
			</Content>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 80px 0 70px;
`
const MainImage = styled(Image)`
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--m)
	}
`
const Headline = styled.h3`
	margin-bottom: var(--m);
`
const Content = styled.div`
	align-self: center;
	grid-row: 1;
	${mobile}{
		grid-row: 2;
		grid-column: span 12;
	}
`
const Text = styled.div`
	
`
const Inner = styled.div`
	
`

TextImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextImage