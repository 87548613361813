import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import 'overlayscrollbars/overlayscrollbars.css'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'


const SelectBox = ({ className, options, onChange, selected }) => {

	const toggleOption = (option) => {
		if (option.value === selected) {
			onChange(null)
		}
		else {
			onChange(option.value)
		}
	}

	return (
		<Wrap className='select-wrap'>
			<Select 
				element='div'
			>
				{options?.map((option, index) => (
					<Option 
						className='h5'
						key={option.value}
						onClick={() => toggleOption(option)}
						selected={selected === option.value}
					>
						{option.label}
					</Option>
				))}
			</Select>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: inline-block;
	position: relative;
	border-bottom: 3px solid var(--dbeige);
`
const Select = styled(OverlayScrollbarsComponent)`
	background-color: var(--bteal20);
	height: 228px;
	overflow-y: scroll;
	padding-top: 15px;
	padding-bottom: 15px;
	box-sizing: border-box;
	.os-scrollbar-handle{
		/* height: 20% !important; */
		background: var(--bteal);
	}
	.os-theme-dark{
		color: var(--bteal);
    --os-size: 21px;
    --os-padding-perpendicular: 7px;
    --os-padding-axis: 20px;
    --os-track-border-radius: 10px;
    --os-handle-interactive-area-offset: 4px;
    --os-handle-border-radius: 10px;
		--os-handle-bg: var(--bteal40);
		--os-handle-bg-active:	var(--bteal60);
		--os-handle-bg-hover:	var(--bteal60);
	}
	${mobile}{
		height: 100%;
		max-height: 228px;
	}
`
const Option = styled.button`
	padding: 7px var(--xs) 7px;
	background-color: ${props => props.selected ? 'var(--teal)' : 'inherit'};
	color: ${props => props.selected ? 'var(--white)' : 'inherit'};
	width: 100%;
	text-align: left;
	text-transform: uppercase;
	padding-right: 20px;
	:hover{
		background-color: ${props => props.selected ? 'var(--teal)' : 'var(--bteal40)'};
		color: ${props => props.selected ? 'var(--white)' : 'inherit'};
	}
`

SelectBox.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	selected: PropTypes.string,
}

export default SelectBox