import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Quote from '~components/Quote'
import Section from '~components/Section'

const QuoteModule = ({ className, content }) => {

	return (
		<Wrap className={className}>
			<Container>
				<StyledQuote 
					quote={content.quote} 
					attribution={content.attribution} 
					light
				/>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	
`
const Container = styled.div`
	grid-column: span 12;
`
const StyledQuote = styled(Quote)`
	margin-left: auto;
	margin-right: auto;
	max-width: 800px;
	margin-top: 100px;
	margin-bottom: 100px;
	${mobile}{
		margin-top: 64px;
		margin-bottom: calc(64px + var(--l));
	}
`

QuoteModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default QuoteModule