import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import { Eyebrow } from '~styles/global'
import { Play, PlayMobile } from '~components/Svg'
import RichText from '~components/RichText'
import ReactPlayer from 'react-player/youtube'

const VideoModule = ({ className, content }) => {
	// this module is used for the podcast embed too

	let video = content?.youTubeUrl
	let podcast = content?.spotifyURL

	if(!video && !podcast) return null

	if(video?.includes('?v=')) {
		video = video.split('?v=')[1]
		video = `https://www.youtube.com/embed/${video}`
	} else if(video?.includes('embed/')) {
		video = video.split('embed/')[1]
		video = `https://www.youtube.com/embed/${video}`
	}

	if(podcast){
		podcast = podcast?.split('/show/')[1]
		podcast = `https://open.spotify.com/embed/show/${podcast}`
	}
	
	const [play, setPlay] = useState(false)
	const [hover, setHover] = useState(false)

	// const url = play ? `${video}?autoplay=1` : `${video}?autoplay=0`

	const playVideo = () => {
		setPlay(true)
	}

	return (
		<Wrap className={className}>
			<Container>
				<Eyebrow>{content.eyebrow}</Eyebrow>
				<Title textShowing={content.text?.length > 0}>{content.title}</Title>
				{content.text?.length > 0 && <Text><RichText moduleStyles content={content.text}/></Text>}
				{video &&
					<VideoContainer 				
						onClick={()=> playVideo()}
					>
						<Overlay playing={play}>
							<Circle>
								<PlayIcon />
								<PlayIconMob />
							</Circle>
						</Overlay>
						<ReactPlayer 
							playing={play}
							url={content?.youTubeUrl}
							controls={1}
							css={css`
								position: absolute; 
								left: 0; 
								right: 0; 
								top: 0; 
								bottom: 0; 
								width: 100% !important;
								height: 100% !important;
							`}
						/>
						{/* <IFrame 
							src={url} 
							frameBorder='0' 
							allowFullScreen
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						/> */}
					</VideoContainer>
				}
				{podcast &&
					<div>
						<iframe src={podcast} width="100%" height="232" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
					</div>
				}
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 100px 0;
	${mobile} {
		padding: 64px 0;
	}
`
const Container = styled.div`
	grid-column: span 10;
	${tablet}{
		grid-column: span 12;
	}
`
const Title = styled.h3`
	margin-bottom: ${props => props.textShowing ? 'var(--m)' : 'var(--xl)'};
`
const Text = styled.div`
	margin-bottom: var(--xl);
`
const VideoContainer = styled.div`
	position: relative; 
	padding-bottom: 56.25%;
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
`
const Overlay = styled.div`
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
	display: grid;
	align-content: center;
	justify-content: center;
	z-index: 2;
	opacity: ${props => props.playing ? '0' : '1'};
	transition: opacity 0.2s;
	pointer-events: ${props => props.playing ? 'none' : 'all'};
	cursor: pointer;
`
const Circle = styled.div`
	background-color: var(--beige20);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 175px;
	height: 175px;
	border-radius: 50%;
	color: var(--bteal);
	${mobile}{
		width: 60px;
		height: 60px;
	}
`
const PlayIcon = styled(Play)`
	width: 56px;
	margin-left: 12px;
	${mobile}{
		display: none;
	}
`
const PlayIconMob = styled(PlayMobile)`
	display: none;
	width: 17px;
	margin-left: 4px;
	${mobile}{
		display: block;
	}
`
const IFrame = styled.iframe`
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
`
VideoModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default VideoModule